
    import './styles.scoped.css';
    export default {
  "resize-active": "awsui_resize-active_lm6vo_11wab_122",
  "resize-side": "awsui_resize-side_lm6vo_11wab_128",
  "resize-bottom": "awsui_resize-bottom_lm6vo_11wab_131",
  "with-motion": "awsui_with-motion_lm6vo_11wab_136",
  "root": "awsui_root_lm6vo_11wab_151",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_11wab_158",
  "layout": "awsui_layout_lm6vo_11wab_163",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_11wab_169",
  "layout-main": "awsui_layout-main_lm6vo_11wab_173",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_11wab_179",
  "unfocusable": "awsui_unfocusable_lm6vo_11wab_184",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_11wab_188",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_11wab_193",
  "content-wrapper": "awsui_content-wrapper_lm6vo_11wab_197",
  "content-overlapped": "awsui_content-overlapped_lm6vo_11wab_201",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_11wab_205"
};
  