
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_xx95z_225",
  "arrow-outer": "awsui_arrow-outer_xjuzf_xx95z_229",
  "arrow-inner": "awsui_arrow-inner_xjuzf_xx95z_229",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_xx95z_260",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_xx95z_260",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_xx95z_263",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_xx95z_263",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_xx95z_266",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_xx95z_266",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_xx95z_266",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_xx95z_266",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_xx95z_269",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_xx95z_269",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_xx95z_269",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_xx95z_269",
  "body": "awsui_body_xjuzf_xx95z_385",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_xx95z_401",
  "has-dismiss": "awsui_has-dismiss_xjuzf_xx95z_405",
  "dismiss": "awsui_dismiss_xjuzf_xx95z_410",
  "dismiss-control": "awsui_dismiss-control_xjuzf_xx95z_417",
  "header-row": "awsui_header-row_xjuzf_xx95z_421",
  "header": "awsui_header_xjuzf_xx95z_421",
  "content": "awsui_content_xjuzf_xx95z_447",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_xx95z_455",
  "container": "awsui_container_xjuzf_xx95z_571",
  "container-body": "awsui_container-body_xjuzf_xx95z_579",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_xx95z_596",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_xx95z_601",
  "fixed-width": "awsui_fixed-width_xjuzf_xx95z_604",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_xx95z_608",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_xx95z_615",
  "container-arrow": "awsui_container-arrow_xjuzf_xx95z_627",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_xx95z_631",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_xx95z_631",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_xx95z_643",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_xx95z_643",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_xx95z_655",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_xx95z_655",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_xx95z_655",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_xx95z_655",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_xx95z_671",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_xx95z_675",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_xx95z_679",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_xx95z_1",
  "refresh": "awsui_refresh_xjuzf_xx95z_817",
  "root": "awsui_root_xjuzf_xx95z_839",
  "trigger": "awsui_trigger_xjuzf_xx95z_853",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_xx95z_860",
  "trigger-inner-text": "awsui_trigger-inner-text_xjuzf_xx95z_889",
  "popover-content": "awsui_popover-content_xjuzf_xx95z_893"
};
  