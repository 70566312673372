import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function AgentEdit () {
  const navigate = useNavigate()
  const { agentId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: agent } = useGet(agentId ? `/api/agent/${agentId}` : null)
  const { data: modelOptions } = useGet('/api/option/type?entity=model&required=true')
  const { data: typeOptions } = useGet('/api/option/type?entity=agent_type&required=true')
  const { data: aggregatorOptions } = useGet('/api/option/aggregator')

  useEffect(() => {
    const defaultFormValues = { topP: 1, frequencyPenalty: 1, presencePenalty: 1 }
    setFormValues(agent ?? defaultFormValues)
  }, [agent])

  const create = usePost('/api/agent', formValues, (resp) => { navigate(`/agent/${resp.id}`) })
  const update = usePut(`/api/agent/${agentId}`, formValues, (resp) => { navigate(`/agent/${agentId}`) })

  async function saveChanges () {
    if (agentId != null) { update() } else { create() }
  }

  async function cancel () {
    if (agentId != null) {
      navigate(`/agent/${agentId}`)
    } else {
      navigate('/agent')
    }
  }

  if (
    formValues != null &&
    aggregatorOptions != null &&
    modelOptions != null &&
    typeOptions != null
  ) {
    const processorType = typeOptions.find(x => x.label === 'Processor')

    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Agent' : 'New Agent'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description *' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        ariaRequired
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Agent Type *' field='agentTypeId'>
                      <RadSelect
                        selectedOption={formValues.agentTypeId ? typeOptions.find(x => x.value === formValues.agentTypeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          const aggregatorId = detail.selectedOption.value === processorType.id ? formValues.aggregatorId : null
                          setFormValues({ ...formValues, agentTypeId: parseInt(detail.selectedOption.value), aggregatorId })
                        }}
                        options={typeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    {formValues.agentTypeId === parseInt(processorType.value) &&
                      <RadFormField label='Aggregator *' field='aggregatorId'>
                        <RadSelect
                          selectedOption={formValues.aggregatorId ? aggregatorOptions.find(x => x.value === formValues.aggregatorId?.toString()) : { value: '', label: '-' }}
                          onChange={({ detail }) => {
                            setFormValues({ ...formValues, aggregatorId: parseInt(detail.selectedOption.value) })
                          }}
                          options={aggregatorOptions}
                          selectedAriaLabel='Selected'
                          empty='No matches found'
                        />
                      </RadFormField>}
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } }
                      ]}
                    >
                      <RadFormField label='Model *' field='model'>
                        <RadSelect
                          selectedOption={formValues.model ? modelOptions.find(x => x.label === formValues.model?.toString()) : { value: '', label: '-' }}
                          onChange={({ detail }) => {
                            if (detail.selectedOption.value !== '') {
                              setFormValues({ ...formValues, model: detail.selectedOption.label })
                            } else {
                              setFormValues({ ...formValues, model: null })
                            }
                          }}
                          options={modelOptions}
                          selectedAriaLabel='Selected'
                          empty='No matches found'
                        />
                      </RadFormField>
                      <RadFormField label='Temperature *' field='temperature'>
                        <RadInput
                          placeholder='Enter temperature'
                          value={formValues.temperature}
                          onChange={({ detail }) => setFormValues({ ...formValues, temperature: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Maximum length' field='maximumLength'>
                        <RadInput
                          type='maximumLength'
                          placeholder='Enter length'
                          value={formValues.maximumLength}
                          onChange={({ detail }) => setFormValues({ ...formValues, maximumLength: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Frequency penalty *' field='frequencyPenalty'>
                        <RadInput
                          placeholder='Enter frequency penalty'
                          value={formValues.frequencyPenalty}
                          onChange={({ detail }) => setFormValues({ ...formValues, frequencyPenalty: detail.value })}
                        />
                      </RadFormField>
                      {/* <RadFormField label='Top P *' field='topP'>
                        <RadInput
                          placeholder='Enter Top P *'
                          value={formValues.topP}
                          onChange={({ detail }) => setFormValues({ ...formValues, topP: detail.value })}
                        />
                      </RadFormField> */}
                    </RadGrid>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } }
                      ]}
                    >
                      <RadFormField label='Presence penalty *' field='presencePenalty'>
                        <RadInput
                          placeholder='Enter presence penalty *'
                          value={formValues.presencePenalty}
                          onChange={({ detail }) => setFormValues({ ...formValues, presencePenalty: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Stop' field='stop'>
                        <RadInput
                          onChange={({ detail }) => setFormValues({ ...formValues, stop: detail.value })}
                          value={formValues.stop}
                          placeholder='Enter stop'
                          ariaRequired
                        />
                      </RadFormField>
                    </RadGrid>
                    <RadFormField label='System Message *' field='systemMessage'>
                      <RadTextarea
                        rows={24}
                        placeholder='Enter system message'
                        ariaRequired
                        value={formValues.systemMessage}
                        onChange={({ detail }) => setFormValues({ ...formValues, systemMessage: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
