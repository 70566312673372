
    import './styles.scoped.css';
    export default {
  "toggle": "awsui_toggle_1uo6m_sge0g_189",
  "drawer-triggers": "awsui_drawer-triggers_1uo6m_sge0g_194",
  "drawer": "awsui_drawer_1uo6m_sge0g_194",
  "drawer-mobile": "awsui_drawer-mobile_1uo6m_sge0g_204",
  "drawer-closed": "awsui_drawer-closed_1uo6m_sge0g_207",
  "drawer-content": "awsui_drawer-content_1uo6m_sge0g_214",
  "drawer-content-clickable": "awsui_drawer-content-clickable_1uo6m_sge0g_229",
  "drawer-resize-content": "awsui_drawer-resize-content_1uo6m_sge0g_239",
  "drawer-triggers-wrapper": "awsui_drawer-triggers-wrapper_1uo6m_sge0g_245",
  "drawer-trigger": "awsui_drawer-trigger_1uo6m_sge0g_194",
  "drawer-trigger-active": "awsui_drawer-trigger-active_1uo6m_sge0g_263",
  "hide": "awsui_hide_1uo6m_sge0g_271"
};
  