import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadExpandableSection } from '../common/RadExpandableSection'
import { RadHeader } from '../common/RadHeader'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function AgentDetail () {
  const navigate = useNavigate()
  const { agentId } = useParams()
  const { data: agent } = useGet(`/api/agent/${agentId}`, true)
  const confirmDelete = useConfirm('Delete agent?', 'Delete agent permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/agent/${agentId}`, () => { navigate('/agent') })

  if (
    agent != null
  ) {
    return (
      <RadAppLayout
        name={agent.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={agent.description}
          >
            {agent.name}
          </RadHeader>
        }
        content={
          <>
            <RadSpaceBetween size='l'>
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    Details
                  </RadHeader>
                }
              >
                <RadSpaceBetween size='l'>
                  <RadColumnLayout columns={4} borders='vertical'>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Model
                      </RadBox>
                      <div>{agent.model}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Temperature
                      </RadBox>
                      <div>{agent.temperature}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Maximum Length
                      </RadBox>
                      <div>{agent.maximumLength ?? '-'}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Frequency Penalty
                      </RadBox>
                      <div>{agent.frequencyPenalty}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Presence Penalty
                      </RadBox>
                      <div>{agent.presencePenalty}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Stop
                      </RadBox>
                      <div>{agent.stop ?? '-'}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Type
                      </RadBox>
                      <div>{agent.type.name}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Aggregator
                      </RadBox>
                      <div>{agent.aggregator?.name ?? '-'}</div>
                    </div>
                  </RadColumnLayout>
                </RadSpaceBetween>
              </RadContainer>
              <RadExpandableSection
                headerText='System Message'
                variant='container'
                defaultExpanded
              >
                <RadLineBreakRenderer text={agent.systemMessage} />
              </RadExpandableSection>
            </RadSpaceBetween>
          </>
        }
      />
    )
  }
}
