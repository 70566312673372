
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_1r9lg_43cy5_233",
  "refresh": "awsui_refresh_1r9lg_43cy5_233",
  "animating": "awsui_animating_1r9lg_43cy5_247",
  "preference-icon--layout-border": "awsui_preference-icon--layout-border_1r9lg_43cy5_270",
  "preference-icon--layout-background": "awsui_preference-icon--layout-background_1r9lg_43cy5_274",
  "preference-icon--layout-header": "awsui_preference-icon--layout-header_1r9lg_43cy5_277",
  "preference-icon--border": "awsui_preference-icon--border_1r9lg_43cy5_280",
  "preference-icon--primary-button": "awsui_preference-icon--primary-button_1r9lg_43cy5_284",
  "preference-icon--secondary": "awsui_preference-icon--secondary_1r9lg_43cy5_287",
  "preference-icon--disabled-element": "awsui_preference-icon--disabled-element_1r9lg_43cy5_290",
  "preference-icon--separator": "awsui_preference-icon--separator_1r9lg_43cy5_293",
  "preference-icon--focus-text": "awsui_preference-icon--focus-text_1r9lg_43cy5_296",
  "preference-icon-refresh--layout-top": "awsui_preference-icon-refresh--layout-top_1r9lg_43cy5_300",
  "preference-icon-refresh--layout-main": "awsui_preference-icon-refresh--layout-main_1r9lg_43cy5_303",
  "preference-icon-refresh--primary": "awsui_preference-icon-refresh--primary_1r9lg_43cy5_306",
  "preference-icon-refresh--disabled": "awsui_preference-icon-refresh--disabled_1r9lg_43cy5_309",
  "preference-icon-refresh--column-header": "awsui_preference-icon-refresh--column-header_1r9lg_43cy5_312",
  "preference-icon-refresh--window": "awsui_preference-icon-refresh--window_1r9lg_43cy5_315",
  "preference-icon-refresh--secondary": "awsui_preference-icon-refresh--secondary_1r9lg_43cy5_319",
  "preference-icon-refresh--default": "awsui_preference-icon-refresh--default_1r9lg_43cy5_322",
  "preference-icon-refresh--separator": "awsui_preference-icon-refresh--separator_1r9lg_43cy5_325",
  "preference-icon-refresh--input-default": "awsui_preference-icon-refresh--input-default_1r9lg_43cy5_328",
  "preference-icon-refresh--heading": "awsui_preference-icon-refresh--heading_1r9lg_43cy5_331",
  "root": "awsui_root_1r9lg_43cy5_335",
  "preferences-button": "awsui_preferences-button_1r9lg_43cy5_348",
  "close-button": "awsui_close-button_1r9lg_43cy5_352",
  "drawer-closed": "awsui_drawer-closed_1r9lg_43cy5_364",
  "drawer-content-side": "awsui_drawer-content-side_1r9lg_43cy5_369",
  "drawer-content-bottom": "awsui_drawer-content-bottom_1r9lg_43cy5_389",
  "position-bottom": "awsui_position-bottom_1r9lg_43cy5_398",
  "position-side": "awsui_position-side_1r9lg_43cy5_425",
  "slider": "awsui_slider_1r9lg_43cy5_431",
  "slider-side": "awsui_slider-side_1r9lg_43cy5_460",
  "slider-wrapper-bottom": "awsui_slider-wrapper-bottom_1r9lg_43cy5_465",
  "slider-wrapper-side": "awsui_slider-wrapper-side_1r9lg_43cy5_476",
  "open-button": "awsui_open-button_1r9lg_43cy5_487",
  "open-button-side": "awsui_open-button-side_1r9lg_43cy5_491",
  "slider-icon": "awsui_slider-icon_1r9lg_43cy5_498",
  "slider-icon-bottom": "awsui_slider-icon-bottom_1r9lg_43cy5_504",
  "slider-icon-side": "awsui_slider-icon-side_1r9lg_43cy5_507",
  "pane-header-wrapper-bottom": "awsui_pane-header-wrapper-bottom_1r9lg_43cy5_511",
  "drawer-mobile": "awsui_drawer-mobile_1r9lg_43cy5_521",
  "drawer-disable-content-paddings": "awsui_drawer-disable-content-paddings_1r9lg_43cy5_524",
  "content-bottom": "awsui_content-bottom_1r9lg_43cy5_532",
  "pane-bottom-center-align": "awsui_pane-bottom-center-align_1r9lg_43cy5_545",
  "pane-bottom-content-nav-padding": "awsui_pane-bottom-content-nav-padding_1r9lg_43cy5_550",
  "pane-bottom-content-tools-padding": "awsui_pane-bottom-content-tools-padding_1r9lg_43cy5_554",
  "content-bottom-max-width": "awsui_content-bottom-max-width_1r9lg_43cy5_558",
  "content-side": "awsui_content-side_1r9lg_43cy5_563",
  "pane-header-wrapper-side": "awsui_pane-header-wrapper-side_1r9lg_43cy5_574",
  "pane-content-wrapper-side": "awsui_pane-content-wrapper-side_1r9lg_43cy5_578",
  "header": "awsui_header_1r9lg_43cy5_583",
  "header-text": "awsui_header-text_1r9lg_43cy5_592",
  "header-actions": "awsui_header-actions_1r9lg_43cy5_603",
  "divider": "awsui_divider_1r9lg_43cy5_611"
};
  