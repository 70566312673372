
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1fj9k_1uvk8_5",
  "navigation": "awsui_navigation_1fj9k_1uvk8_9",
  "navigation-toggle": "awsui_navigation-toggle_1fj9k_1uvk8_13",
  "navigation-close": "awsui_navigation-close_1fj9k_1uvk8_17",
  "content": "awsui_content_1fj9k_1uvk8_21",
  "notifications": "awsui_notifications_1fj9k_1uvk8_25",
  "breadcrumbs": "awsui_breadcrumbs_1fj9k_1uvk8_29",
  "tools": "awsui_tools_1fj9k_1uvk8_33",
  "tools-close": "awsui_tools-close_1fj9k_1uvk8_37",
  "tools-toggle": "awsui_tools-toggle_1fj9k_1uvk8_41",
  "drawer-closed": "awsui_drawer-closed_1fj9k_1uvk8_45",
  "mobile-bar": "awsui_mobile-bar_1fj9k_1uvk8_49",
  "disable-body-scroll-root": "awsui_disable-body-scroll-root_1fj9k_1uvk8_53",
  "drawers-trigger": "awsui_drawers-trigger_1fj9k_1uvk8_57",
  "active-drawer": "awsui_active-drawer_1fj9k_1uvk8_61",
  "active-drawer-close-button": "awsui_active-drawer-close-button_1fj9k_1uvk8_65",
  "drawers-slider": "awsui_drawers-slider_1fj9k_1uvk8_69"
};
  