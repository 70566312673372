
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_1s0dz_121",
  "button": "awsui_button_vjswe_1s0dz_125",
  "variant-normal": "awsui_variant-normal_vjswe_1s0dz_154",
  "is-activated": "awsui_is-activated_vjswe_1s0dz_167",
  "variant-icon": "awsui_variant-icon_vjswe_1s0dz_194",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_1s0dz_194",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_1s0dz_194",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_1s0dz_212",
  "disabled": "awsui_disabled_vjswe_1s0dz_230",
  "variant-primary": "awsui_variant-primary_vjswe_1s0dz_238",
  "variant-link": "awsui_variant-link_vjswe_1s0dz_322",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_1s0dz_574",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_1s0dz_829",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_1s0dz_917",
  "button-no-text": "awsui_button-no-text_vjswe_1s0dz_1001",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_1s0dz_1005",
  "full-width": "awsui_full-width_vjswe_1s0dz_1008",
  "icon-left": "awsui_icon-left_vjswe_1s0dz_1027",
  "icon-right": "awsui_icon-right_vjswe_1s0dz_1032",
  "icon": "awsui_icon_vjswe_1s0dz_1027"
};
  