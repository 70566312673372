
    import './styles.scoped.css';
    export default {
  "background": "awsui_background_hyvsj_ai7q0_117",
  "scrolling-background": "awsui_scrolling-background_hyvsj_ai7q0_120",
  "sticky-background": "awsui_sticky-background_hyvsj_ai7q0_126",
  "has-sticky-notifications": "awsui_has-sticky-notifications_hyvsj_ai7q0_134",
  "breadcrumbs": "awsui_breadcrumbs_hyvsj_ai7q0_145",
  "has-sticky-background": "awsui_has-sticky-background_hyvsj_ai7q0_155",
  "drawers-container": "awsui_drawers-container_hyvsj_ai7q0_167",
  "has-open-drawer": "awsui_has-open-drawer_hyvsj_ai7q0_178",
  "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_ai7q0_202",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_hyvsj_ai7q0_207",
  "has-multiple-triggers": "awsui_has-multiple-triggers_hyvsj_ai7q0_226",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_hyvsj_ai7q0_236",
  "drawers-trigger-content": "awsui_drawers-trigger-content_hyvsj_ai7q0_240",
  "drawers-trigger-overflow": "awsui_drawers-trigger-overflow_hyvsj_ai7q0_254",
  "drawers-trigger": "awsui_drawers-trigger_hyvsj_ai7q0_240",
  "drawer": "awsui_drawer_hyvsj_ai7q0_167",
  "drawer-content-container": "awsui_drawer-content-container_hyvsj_ai7q0_284",
  "drawer-close-button": "awsui_drawer-close-button_hyvsj_ai7q0_293",
  "drawer-content": "awsui_drawer-content_hyvsj_ai7q0_284",
  "drawer-content-hidden": "awsui_drawer-content-hidden_hyvsj_ai7q0_301",
  "drawer-slider": "awsui_drawer-slider_hyvsj_ai7q0_304",
  "is-drawer-open": "awsui_is-drawer-open_hyvsj_ai7q0_311",
  "content": "awsui_content_hyvsj_ai7q0_336",
  "layout": "awsui_layout_hyvsj_ai7q0_361",
  "has-max-content-width": "awsui_has-max-content-width_hyvsj_ai7q0_444",
  "content-type-dashboard": "awsui_content-type-dashboard_hyvsj_ai7q0_459",
  "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_ai7q0_476",
  "is-hide-mobile-toolbar": "awsui_is-hide-mobile-toolbar_hyvsj_ai7q0_479",
  "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_ai7q0_505",
  "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_ai7q0_508",
  "content-first-child-notifications": "awsui_content-first-child-notifications_hyvsj_ai7q0_518",
  "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_ai7q0_521",
  "content-first-child-header": "awsui_content-first-child-header_hyvsj_ai7q0_524",
  "has-header": "awsui_has-header_hyvsj_ai7q0_524",
  "content-first-child-main": "awsui_content-first-child-main_hyvsj_ai7q0_545",
  "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_ai7q0_545",
  "has-split-panel": "awsui_has-split-panel_hyvsj_ai7q0_588",
  "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_ai7q0_588",
  "block-body-scroll": "awsui_block-body-scroll_hyvsj_ai7q0_602",
  "unfocusable": "awsui_unfocusable_hyvsj_ai7q0_607",
  "container": "awsui_container_hyvsj_ai7q0_617",
  "is-navigation-open": "awsui_is-navigation-open_hyvsj_ai7q0_642",
  "is-tools-open": "awsui_is-tools-open_hyvsj_ai7q0_645",
  "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_ai7q0_645",
  "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_ai7q0_645",
  "has-active-drawer": "awsui_has-active-drawer_hyvsj_ai7q0_645",
  "mobile-toolbar": "awsui_mobile-toolbar_hyvsj_ai7q0_654",
  "mobile-toolbar-nav": "awsui_mobile-toolbar-nav_hyvsj_ai7q0_670",
  "mobile-toolbar-breadcrumbs": "awsui_mobile-toolbar-breadcrumbs_hyvsj_ai7q0_674",
  "mobile-toolbar-tools": "awsui_mobile-toolbar-tools_hyvsj_ai7q0_678",
  "navigation-container": "awsui_navigation-container_hyvsj_ai7q0_687",
  "show-navigation": "awsui_show-navigation_hyvsj_ai7q0_731",
  "animating": "awsui_animating_hyvsj_ai7q0_753",
  "showButtons": "awsui_showButtons_hyvsj_ai7q0_1",
  "navigation": "awsui_navigation_hyvsj_ai7q0_687",
  "openNavigation": "awsui_openNavigation_hyvsj_ai7q0_1",
  "animated-content": "awsui_animated-content_hyvsj_ai7q0_815",
  "hide-navigation": "awsui_hide-navigation_hyvsj_ai7q0_824",
  "notifications": "awsui_notifications_hyvsj_ai7q0_834",
  "sticky-notifications": "awsui_sticky-notifications_hyvsj_ai7q0_840",
  "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_ai7q0_857",
  "position-bottom": "awsui_position-bottom_hyvsj_ai7q0_904",
  "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_ai7q0_1",
  "split-panel-side": "awsui_split-panel-side_hyvsj_ai7q0_933",
  "position-side": "awsui_position-side_hyvsj_ai7q0_946",
  "tools-container": "awsui_tools-container_hyvsj_ai7q0_965",
  "tools": "awsui_tools_hyvsj_ai7q0_965",
  "openTools": "awsui_openTools_hyvsj_ai7q0_1",
  "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_ai7q0_1060",
  "hide-tools": "awsui_hide-tools_hyvsj_ai7q0_1070",
  "show-tools": "awsui_show-tools_hyvsj_ai7q0_1082",
  "has-tools-form": "awsui_has-tools-form_hyvsj_ai7q0_1060",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_hyvsj_ai7q0_1142",
  "trigger": "awsui_trigger_hyvsj_ai7q0_1142",
  "selected": "awsui_selected_hyvsj_ai7q0_1206",
  "badge": "awsui_badge_hyvsj_ai7q0_1226",
  "trigger-wrapper": "awsui_trigger-wrapper_hyvsj_ai7q0_1230",
  "dot": "awsui_dot_hyvsj_ai7q0_1236"
};
  