
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_18wu0_1cwbl_113",
  "box": "awsui_box_18wu0_1cwbl_250",
  "p-variant": "awsui_p-variant_18wu0_1cwbl_250",
  "color-default": "awsui_color-default_18wu0_1cwbl_250",
  "b-variant": "awsui_b-variant_18wu0_1cwbl_250",
  "strong-variant": "awsui_strong-variant_18wu0_1cwbl_250",
  "code-variant": "awsui_code-variant_18wu0_1cwbl_250",
  "pre-variant": "awsui_pre-variant_18wu0_1cwbl_250",
  "samp-variant": "awsui_samp-variant_18wu0_1cwbl_250",
  "h1-variant": "awsui_h1-variant_18wu0_1cwbl_254",
  "h2-variant": "awsui_h2-variant_18wu0_1cwbl_254",
  "h3-variant": "awsui_h3-variant_18wu0_1cwbl_254",
  "h4-variant": "awsui_h4-variant_18wu0_1cwbl_254",
  "h5-variant": "awsui_h5-variant_18wu0_1cwbl_254",
  "small-variant": "awsui_small-variant_18wu0_1cwbl_258",
  "a-variant": "awsui_a-variant_18wu0_1cwbl_262",
  "font-size-default": "awsui_font-size-default_18wu0_1cwbl_266",
  "font-weight-default": "awsui_font-weight-default_18wu0_1cwbl_306",
  "key-label-variant": "awsui_key-label-variant_18wu0_1cwbl_346",
  "value-large-variant": "awsui_value-large-variant_18wu0_1cwbl_353",
  "font-weight-heavy": "awsui_font-weight-heavy_18wu0_1cwbl_360",
  "color-inverted": "awsui_color-inverted_18wu0_1cwbl_365",
  "color-text-label": "awsui_color-text-label_18wu0_1cwbl_368",
  "color-text-body-secondary": "awsui_color-text-body-secondary_18wu0_1cwbl_371",
  "color-text-status-error": "awsui_color-text-status-error_18wu0_1cwbl_374",
  "color-text-status-success": "awsui_color-text-status-success_18wu0_1cwbl_377",
  "color-text-status-info": "awsui_color-text-status-info_18wu0_1cwbl_380",
  "color-text-status-inactive": "awsui_color-text-status-inactive_18wu0_1cwbl_383",
  "color-text-status-warning": "awsui_color-text-status-warning_18wu0_1cwbl_386",
  "color-inherit": "awsui_color-inherit_18wu0_1cwbl_389",
  "font-size-body-s": "awsui_font-size-body-s_18wu0_1cwbl_392",
  "font-size-body-m": "awsui_font-size-body-m_18wu0_1cwbl_397",
  "font-size-heading-xs": "awsui_font-size-heading-xs_18wu0_1cwbl_401",
  "font-size-heading-s": "awsui_font-size-heading-s_18wu0_1cwbl_405",
  "font-size-heading-m": "awsui_font-size-heading-m_18wu0_1cwbl_410",
  "font-size-heading-l": "awsui_font-size-heading-l_18wu0_1cwbl_415",
  "font-size-heading-xl": "awsui_font-size-heading-xl_18wu0_1cwbl_420",
  "font-size-display-l": "awsui_font-size-display-l_18wu0_1cwbl_425",
  "font-weight-light": "awsui_font-weight-light_18wu0_1cwbl_430",
  "font-weight-normal": "awsui_font-weight-normal_18wu0_1cwbl_433",
  "font-weight-bold": "awsui_font-weight-bold_18wu0_1cwbl_436",
  "t-left": "awsui_t-left_18wu0_1cwbl_443",
  "t-right": "awsui_t-right_18wu0_1cwbl_447",
  "t-center": "awsui_t-center_18wu0_1cwbl_451",
  "p-n": "awsui_p-n_18wu0_1cwbl_576",
  "p-top-n": "awsui_p-top-n_18wu0_1cwbl_580",
  "p-vertical-n": "awsui_p-vertical-n_18wu0_1cwbl_581",
  "p-right-n": "awsui_p-right-n_18wu0_1cwbl_585",
  "p-horizontal-n": "awsui_p-horizontal-n_18wu0_1cwbl_586",
  "p-bottom-n": "awsui_p-bottom-n_18wu0_1cwbl_590",
  "p-left-n": "awsui_p-left-n_18wu0_1cwbl_595",
  "p-xxxs": "awsui_p-xxxs_18wu0_1cwbl_600",
  "p-top-xxxs": "awsui_p-top-xxxs_18wu0_1cwbl_604",
  "p-vertical-xxxs": "awsui_p-vertical-xxxs_18wu0_1cwbl_605",
  "p-right-xxxs": "awsui_p-right-xxxs_18wu0_1cwbl_609",
  "p-horizontal-xxxs": "awsui_p-horizontal-xxxs_18wu0_1cwbl_610",
  "p-bottom-xxxs": "awsui_p-bottom-xxxs_18wu0_1cwbl_614",
  "p-left-xxxs": "awsui_p-left-xxxs_18wu0_1cwbl_619",
  "p-xxs": "awsui_p-xxs_18wu0_1cwbl_624",
  "p-top-xxs": "awsui_p-top-xxs_18wu0_1cwbl_628",
  "p-vertical-xxs": "awsui_p-vertical-xxs_18wu0_1cwbl_629",
  "p-right-xxs": "awsui_p-right-xxs_18wu0_1cwbl_633",
  "p-horizontal-xxs": "awsui_p-horizontal-xxs_18wu0_1cwbl_634",
  "p-bottom-xxs": "awsui_p-bottom-xxs_18wu0_1cwbl_638",
  "p-left-xxs": "awsui_p-left-xxs_18wu0_1cwbl_643",
  "p-xs": "awsui_p-xs_18wu0_1cwbl_648",
  "p-top-xs": "awsui_p-top-xs_18wu0_1cwbl_652",
  "p-vertical-xs": "awsui_p-vertical-xs_18wu0_1cwbl_653",
  "p-right-xs": "awsui_p-right-xs_18wu0_1cwbl_657",
  "p-horizontal-xs": "awsui_p-horizontal-xs_18wu0_1cwbl_658",
  "p-bottom-xs": "awsui_p-bottom-xs_18wu0_1cwbl_662",
  "p-left-xs": "awsui_p-left-xs_18wu0_1cwbl_667",
  "p-s": "awsui_p-s_18wu0_1cwbl_672",
  "p-top-s": "awsui_p-top-s_18wu0_1cwbl_676",
  "p-vertical-s": "awsui_p-vertical-s_18wu0_1cwbl_677",
  "p-right-s": "awsui_p-right-s_18wu0_1cwbl_681",
  "p-horizontal-s": "awsui_p-horizontal-s_18wu0_1cwbl_682",
  "p-bottom-s": "awsui_p-bottom-s_18wu0_1cwbl_686",
  "p-left-s": "awsui_p-left-s_18wu0_1cwbl_691",
  "p-m": "awsui_p-m_18wu0_1cwbl_696",
  "p-top-m": "awsui_p-top-m_18wu0_1cwbl_700",
  "p-vertical-m": "awsui_p-vertical-m_18wu0_1cwbl_701",
  "p-right-m": "awsui_p-right-m_18wu0_1cwbl_705",
  "p-horizontal-m": "awsui_p-horizontal-m_18wu0_1cwbl_706",
  "p-bottom-m": "awsui_p-bottom-m_18wu0_1cwbl_710",
  "p-left-m": "awsui_p-left-m_18wu0_1cwbl_715",
  "p-l": "awsui_p-l_18wu0_1cwbl_595",
  "p-top-l": "awsui_p-top-l_18wu0_1cwbl_724",
  "p-vertical-l": "awsui_p-vertical-l_18wu0_1cwbl_725",
  "p-right-l": "awsui_p-right-l_18wu0_1cwbl_729",
  "p-horizontal-l": "awsui_p-horizontal-l_18wu0_1cwbl_730",
  "p-bottom-l": "awsui_p-bottom-l_18wu0_1cwbl_734",
  "p-left-l": "awsui_p-left-l_18wu0_1cwbl_739",
  "p-xl": "awsui_p-xl_18wu0_1cwbl_744",
  "p-top-xl": "awsui_p-top-xl_18wu0_1cwbl_748",
  "p-vertical-xl": "awsui_p-vertical-xl_18wu0_1cwbl_749",
  "p-right-xl": "awsui_p-right-xl_18wu0_1cwbl_753",
  "p-horizontal-xl": "awsui_p-horizontal-xl_18wu0_1cwbl_754",
  "p-bottom-xl": "awsui_p-bottom-xl_18wu0_1cwbl_758",
  "p-left-xl": "awsui_p-left-xl_18wu0_1cwbl_763",
  "p-xxl": "awsui_p-xxl_18wu0_1cwbl_768",
  "p-top-xxl": "awsui_p-top-xxl_18wu0_1cwbl_772",
  "p-vertical-xxl": "awsui_p-vertical-xxl_18wu0_1cwbl_773",
  "p-right-xxl": "awsui_p-right-xxl_18wu0_1cwbl_777",
  "p-horizontal-xxl": "awsui_p-horizontal-xxl_18wu0_1cwbl_778",
  "p-bottom-xxl": "awsui_p-bottom-xxl_18wu0_1cwbl_782",
  "p-left-xxl": "awsui_p-left-xxl_18wu0_1cwbl_787",
  "p-xxxl": "awsui_p-xxxl_18wu0_1cwbl_792",
  "p-top-xxxl": "awsui_p-top-xxxl_18wu0_1cwbl_796",
  "p-vertical-xxxl": "awsui_p-vertical-xxxl_18wu0_1cwbl_797",
  "p-right-xxxl": "awsui_p-right-xxxl_18wu0_1cwbl_801",
  "p-horizontal-xxxl": "awsui_p-horizontal-xxxl_18wu0_1cwbl_802",
  "p-bottom-xxxl": "awsui_p-bottom-xxxl_18wu0_1cwbl_806",
  "p-left-xxxl": "awsui_p-left-xxxl_18wu0_1cwbl_811",
  "m-n": "awsui_m-n_18wu0_1cwbl_816",
  "m-top-n": "awsui_m-top-n_18wu0_1cwbl_820",
  "m-vertical-n": "awsui_m-vertical-n_18wu0_1cwbl_821",
  "m-right-n": "awsui_m-right-n_18wu0_1cwbl_825",
  "m-horizontal-n": "awsui_m-horizontal-n_18wu0_1cwbl_826",
  "m-bottom-n": "awsui_m-bottom-n_18wu0_1cwbl_830",
  "m-left-n": "awsui_m-left-n_18wu0_1cwbl_835",
  "m-xxxs": "awsui_m-xxxs_18wu0_1cwbl_840",
  "m-top-xxxs": "awsui_m-top-xxxs_18wu0_1cwbl_844",
  "m-vertical-xxxs": "awsui_m-vertical-xxxs_18wu0_1cwbl_845",
  "m-right-xxxs": "awsui_m-right-xxxs_18wu0_1cwbl_849",
  "m-horizontal-xxxs": "awsui_m-horizontal-xxxs_18wu0_1cwbl_850",
  "m-bottom-xxxs": "awsui_m-bottom-xxxs_18wu0_1cwbl_854",
  "m-left-xxxs": "awsui_m-left-xxxs_18wu0_1cwbl_859",
  "m-xxs": "awsui_m-xxs_18wu0_1cwbl_864",
  "m-top-xxs": "awsui_m-top-xxs_18wu0_1cwbl_868",
  "m-vertical-xxs": "awsui_m-vertical-xxs_18wu0_1cwbl_869",
  "m-right-xxs": "awsui_m-right-xxs_18wu0_1cwbl_873",
  "m-horizontal-xxs": "awsui_m-horizontal-xxs_18wu0_1cwbl_874",
  "m-bottom-xxs": "awsui_m-bottom-xxs_18wu0_1cwbl_878",
  "m-left-xxs": "awsui_m-left-xxs_18wu0_1cwbl_883",
  "m-xs": "awsui_m-xs_18wu0_1cwbl_888",
  "m-top-xs": "awsui_m-top-xs_18wu0_1cwbl_892",
  "m-vertical-xs": "awsui_m-vertical-xs_18wu0_1cwbl_893",
  "m-right-xs": "awsui_m-right-xs_18wu0_1cwbl_897",
  "m-horizontal-xs": "awsui_m-horizontal-xs_18wu0_1cwbl_898",
  "m-bottom-xs": "awsui_m-bottom-xs_18wu0_1cwbl_902",
  "m-left-xs": "awsui_m-left-xs_18wu0_1cwbl_907",
  "m-s": "awsui_m-s_18wu0_1cwbl_912",
  "m-top-s": "awsui_m-top-s_18wu0_1cwbl_916",
  "m-vertical-s": "awsui_m-vertical-s_18wu0_1cwbl_917",
  "m-right-s": "awsui_m-right-s_18wu0_1cwbl_921",
  "m-horizontal-s": "awsui_m-horizontal-s_18wu0_1cwbl_922",
  "m-bottom-s": "awsui_m-bottom-s_18wu0_1cwbl_926",
  "m-left-s": "awsui_m-left-s_18wu0_1cwbl_931",
  "m-m": "awsui_m-m_18wu0_1cwbl_936",
  "m-top-m": "awsui_m-top-m_18wu0_1cwbl_940",
  "m-vertical-m": "awsui_m-vertical-m_18wu0_1cwbl_941",
  "m-right-m": "awsui_m-right-m_18wu0_1cwbl_945",
  "m-horizontal-m": "awsui_m-horizontal-m_18wu0_1cwbl_946",
  "m-bottom-m": "awsui_m-bottom-m_18wu0_1cwbl_950",
  "m-left-m": "awsui_m-left-m_18wu0_1cwbl_955",
  "m-l": "awsui_m-l_18wu0_1cwbl_835",
  "m-top-l": "awsui_m-top-l_18wu0_1cwbl_964",
  "m-vertical-l": "awsui_m-vertical-l_18wu0_1cwbl_965",
  "m-right-l": "awsui_m-right-l_18wu0_1cwbl_969",
  "m-horizontal-l": "awsui_m-horizontal-l_18wu0_1cwbl_970",
  "m-bottom-l": "awsui_m-bottom-l_18wu0_1cwbl_974",
  "m-left-l": "awsui_m-left-l_18wu0_1cwbl_979",
  "m-xl": "awsui_m-xl_18wu0_1cwbl_984",
  "m-top-xl": "awsui_m-top-xl_18wu0_1cwbl_988",
  "m-vertical-xl": "awsui_m-vertical-xl_18wu0_1cwbl_989",
  "m-right-xl": "awsui_m-right-xl_18wu0_1cwbl_993",
  "m-horizontal-xl": "awsui_m-horizontal-xl_18wu0_1cwbl_994",
  "m-bottom-xl": "awsui_m-bottom-xl_18wu0_1cwbl_998",
  "m-left-xl": "awsui_m-left-xl_18wu0_1cwbl_1003",
  "m-xxl": "awsui_m-xxl_18wu0_1cwbl_1008",
  "m-top-xxl": "awsui_m-top-xxl_18wu0_1cwbl_1012",
  "m-vertical-xxl": "awsui_m-vertical-xxl_18wu0_1cwbl_1013",
  "m-right-xxl": "awsui_m-right-xxl_18wu0_1cwbl_1017",
  "m-horizontal-xxl": "awsui_m-horizontal-xxl_18wu0_1cwbl_1018",
  "m-bottom-xxl": "awsui_m-bottom-xxl_18wu0_1cwbl_1022",
  "m-left-xxl": "awsui_m-left-xxl_18wu0_1cwbl_1027",
  "m-xxxl": "awsui_m-xxxl_18wu0_1cwbl_1032",
  "m-top-xxxl": "awsui_m-top-xxxl_18wu0_1cwbl_1036",
  "m-vertical-xxxl": "awsui_m-vertical-xxxl_18wu0_1cwbl_1037",
  "m-right-xxxl": "awsui_m-right-xxxl_18wu0_1cwbl_1041",
  "m-horizontal-xxxl": "awsui_m-horizontal-xxxl_18wu0_1cwbl_1042",
  "m-bottom-xxxl": "awsui_m-bottom-xxxl_18wu0_1cwbl_1046",
  "m-left-xxxl": "awsui_m-left-xxxl_18wu0_1cwbl_1051",
  "d-block": "awsui_d-block_18wu0_1cwbl_1056",
  "d-inline": "awsui_d-inline_18wu0_1cwbl_1059",
  "d-inline-block": "awsui_d-inline-block_18wu0_1cwbl_1062",
  "d-none": "awsui_d-none_18wu0_1cwbl_1065",
  "f-left": "awsui_f-left_18wu0_1cwbl_1069",
  "f-right": "awsui_f-right_18wu0_1cwbl_1073"
};
  